import { createSSRApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js';
import * as Sentry from '@sentry/vue';
import Plausible from 'plausible-tracker';
import { Ziggy } from '@/ziggy';
import Notifications from 'notiwind';
import VueEasyLightbox from 'vue-easy-lightbox';
import { captureConsoleIntegration, httpClientIntegration } from '@sentry/integrations';

console.log('%cStop!', "background-color: red; color: white; font-size: 24px; font-weight: bold; padding: 10px 20px; border-radius: 10px;");
console.log('%cAls iemand je heeft gevraagd om hier iets te copy/pasten dan heb je een 11/10 kans dat je opgelicht wordt.', "font-size: 20px");
console.log('%cAls je hier iets in paste dan kan dat hackers toegang geven tot je account.', "font-size: 20px");
console.log('%cTenzij je weet wat je doet, sluit deze tabblad en kom niet terug. Blijf veilig!', "font-size: 20px");

if (import.meta.env.VITE_PLAUSIBLE_ADDRESS) {
    const { enableAutoPageviews } = Plausible({
        domain: window.location.hostname,
        apiHost: import.meta.env.VITE_PLAUSIBLE_ADDRESS
    });

    enableAutoPageviews();
}

const addSentry = (app) => {
    const dsn = import.meta.env.VITE_SENTRY_VUE_DSN;

    Sentry.init({
        app,
        dsn,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            captureConsoleIntegration(),
            httpClientIntegration(),
        ],

        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0.5,
        trackComponents: true,
        tracePropagationTargets: ["localhost", /^https:\/\//],
        hooks: ['activate', 'create', 'unmount', 'mount', 'update'],

        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
    });
};

createInertiaApp({
    title: (title) => `${title} - De Bestel App`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createSSRApp({ render: () => h(App, props) });

        const ziggyConfig = {
            ...Ziggy,
            location: window.location,
        };

        app.mixin({
            route: (name, params, absolute, config = ziggyConfig) => route(name, params, absolute, config)
        });

        app.use(plugin);
        app.use(ZiggyVue, ziggyConfig);
        // app.use(RayPlugin, { interceptErrors: true, host: '127.0.0.1', port: 23517 });

        if (import.meta.env.VITE_SENTRY_VUE_DSN) {
            addSentry(app);
        }

        app.use(VueEasyLightbox);
        app.use(Notifications);

        app.mount(el);

        return { app };
    },
    progress: {
        color: 'var(--color-blue-500)',
        includeCSS: true,
    },
});
